/***** Root Class *****/
:root {
  /* Colors */
  --transparentColor: transparent;
  --blackColor: #000000;
  --whiteColor: #FFFFFF;
  --redColor: #FF0000;
  --turquoiseblueColor: #3399CC;
}

body, html {
  /* height: 100%; */
  min-height: 100%;
  position: relative;
}
/**********************/

/***** Navigation Bar *****/
#navbar {
  /* background-color: var(--transparentColor); */
  background-color: var(--blackColor);
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.7s; /* Transition effect when sliding down (and up) */
}

#navbar.scrolled {
  background-color: #000000 !important;
  transition: background-color 0.7s linear;
}

/* #navbarMobile {
  background-color: var(--whiteColor);
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.7s; /* Transition effect when sliding down (and up)  
} */

/* Style the navbar links */
#navbar a {
  float: left;
  display: block;
  color: var(--whiteColor);
  font-weight: bold;
  text-align: center;
  padding: 15px;
  text-decoration: none;
}

/* #navbarMobile a {
  color: var(--turquoiseblueColor);
  font-weight: bold;
} */

#navbar a:hover {
  color: var(--turquoiseblueColor);
}

/* Navigation Dropdown Buttons */
.dropbtn {
  background-color: var(--transparentColor);
  color: var(--whiteColor);
  font-weight: bold;
  padding: 16px;
  font-size: 16px;
  border: none;
}
 
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
  
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
  
/* Links inside the dropdown */
.dropdown-content a {
  color: var(--blackColor);
  padding: 16px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {background-color: #333;} */
  
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
  
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {color: var(--turquoiseblueColor);}
/**************************/

/***** Footer *****/
#FooterMain {
  background-color: var(--blackColor);
  width: 100%;
  height: 100%;
  color: var(--whiteColor);
  text-align: center;
  bottom: 0;
  padding: 40px;
}

#FooterContent {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: small;
}

#FooterMobileContent {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: x-small;
}
/******************/

/***** Home *****/
/* Background Image Slides */
.ImgSlides {display: none;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 750px) {
  .text {font-size: 11px}
  #navbar {
    background-color: var(--whiteColor);
    position: fixed;
    width: 100%;
    z-index: 1000;    
  }

  #navbar a {
    color: var(--turquoiseblueColor);
    font-weight: bold;
  }
}

#HomeStyle {
  /* background-color: var(--whiteColor); */
  background-color: rgba(240, 255, 255, 0.9);
  position: absolute;
  width: 40%;
  padding: 30px;
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  /* animation: HeaderAnimation 1s; */
}

#HomeMobileStyle {
  background-color: white;
  text-align: center;
  margin-bottom: 20px;
}

.HomeLabel {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  font-family: arial;
  animation: PortalDashboardStyleHeaderAnimation 1s;
}

.HomeMobileLabel {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  font-family: arial;
  animation: PortalDashboardStyleHeaderAnimation 1s;
}
/****************/

/***** About *****/
#AboutStyle {
  /* background-color: rgba(250, 255, 255, 0.9); */
  /* position: absolute; */
  background-color: var((--whiteColor));
  width: 100%;
  height: 60%;
  padding: 30px;
  /* padding-left: 200px; */
  top: 500px;
  left: 0;
  right: 0;
  margin: auto;
}

#AboutMobileStyle {
  text-align: center;
  margin-bottom: 20px;
}

#AboutHeaderFontStyle {
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left;
  font-family: arial;
}

#AboutImage {
  display: inline-block;
  vertical-align: top;
  padding-top: 50px;
  padding-left: 50px;
}

#AboutSummaryStyle {
  /* display: inline-block; */
  vertical-align: top;
  padding-top: 20px;
  padding-left: 20px;
  font-weight: bold;
  /* width: 330px; */
}

#AboutMobileSummaryStyle {
  vertical-align: top;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}

#AboutSummary {
  font-size: 20px;
  letter-spacing: 1px;
  /* position: absolute; */
  /* width: 70%; */
}

#AboutContact {
  margin-top: 50px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(51, 153, 204, 0.9);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

#AboutLabel {
  color: var(--turquoiseblueColor);
  font-size: 60px;
  font-family: arial;
  letter-spacing: 2px;
}

#AboutMobileLabel {
  color: var(--turquoiseblueColor);
  font-size: 40px;
  font-family: arial;
  letter-spacing: 2px;
}
/*****************/

/***** SearchHomes *****/
#SearchHomesStyle {
  background-color: var((--whiteColor));
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 80px;
  top: 500px;
  left: 0;
  right: 0;
  margin: auto;
}

#SearchHomesMobileStyle {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 20px;
}
/***********************/

/***** Portal Dashboard *****/
#PortalDashboardStyle {
  /* background-color: var(--whiteColor); */
  background-color: rgba(240, 255, 255, 0.9);
  position: absolute;
  width: 35%;
  padding: 30px;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  animation: PortalDashboardStyleHeaderAnimation 1s;
}

#PortalDashboardMobileStyle {
  text-align: center;
  margin-bottom: 20px;
}

@keyframes PortalDashboardStyleHeaderAnimation {
  from {
    margin-top: 150px;
    opacity: 0.1;
  }
  to {
    margin-top: 0px;
  }
}
/****************************/

/***** MySavedList *****/
#MySavedListDeleteButton {
  color: var(--redColor);
  border-color: var(--transparentColor);
  background-color: var(--transparentColor);
  text-align: center;
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
/***********************/

/***** Contact *****/
#ContactStyle {
  /* background-color: var(--whiteColor); */
  background-color: rgba(240, 255, 255, 0.9);
  position: absolute;
  width: 35%;
  padding: 30px;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  animation: ContactHeaderAnimation 1s;
}

#ContactMobileStyle {
  text-align: center;
  margin-bottom: 20px;
}

@keyframes ContactHeaderAnimation {
  from {
    margin-top: 150px;
    opacity: 0.1;
  }
  to {
    margin-top: 0px;
  }
}

#GeneralHeaderFontStyle {
  color: var(--turquoiseblueColor);
  font-size: 60px;
  font-family: arial;
  letter-spacing: 2px;
}

#GeneralHeaderFontMobileStyle {
  color: var(--turquoiseblueColor);
  font-size: 35px;
  font-family: arial;
  letter-spacing: 2px;
}

/* Updater on smaller screens */
@media only screen and (max-width: 1500px) {
  #ContactStyle {
    /* background-color: var(--whiteColor); */
    background-color: rgba(240, 255, 255, 0.9);
    position: absolute;
    width: 55%;
    padding: 20px;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    animation: ContactHeaderAnimation 1s;
  }

  #GeneralHeaderFontStyle {
    color: var(--turquoiseblueColor);
    font-size: 40px;
    font-family: arial;
    letter-spacing: 2px;
  }
}

.GeneralLabel {
  font-weight: bold;
  letter-spacing: 1px;
  text-align: left;
}

.GeneralInputs {
  background-color: var(--transparentColor);
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  width: 60%;
  text-align: center;
}

.GeneralInputs:focus {
  outline: none;
}

.GeneralMessageInputs {
  background-color: var(--transparentColor);
  border-style: groove;
  width: 60%;
  text-align: center;
}

.GeneralMessageInputs:focus {
  outline: none;
}

#GeneralSubmitButton {
  text-align: center;
}

#GeneralSubmitButton button{
  font-size: 16px;
  color: var(--blackColor);
  border-color: var(--blackColor);
  background-color: var(--transparentColor);
}

#GeneralSubmitButton button:hover{
  color: var(--whiteColor);
  background-color: var(--turquoiseblueColor);
}
/*******************/